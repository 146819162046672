import React, { useEffect, useRef } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import CardWithTitle from '../designSystem/CardWithTitle';
import PhoneInput from '../designSystem/PhoneInput';
import {
  showSnackbar,
  startPageLoader,
  stopPageLoader,
} from '../../redux/actions/appAction';
import { signInWithNumber } from '../firebase/helper';
import firebase from '../../components/firebase';

const EnterNumberForm = ({
  classes,
  number,
  setNumber,
  setShowOtp,
  orgNumber,
  _dispatch,
  props,
  handleAuthChange,
  onBack,
}) => {
  const getOtp = recaptcha => {
    _dispatch(startPageLoader());

    signInWithNumber(number, recaptcha)
      .then(res => {
        if (res) {
          setShowOtp(true);
          _dispatch(stopPageLoader());
        }
      })
      .catch(error => {
        _dispatch(stopPageLoader());
        _dispatch(showSnackbar(error.message));
      });
  };

  useEffect(() => {
    if (orgNumber) {
      const recaptcha = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        { size: 'invisible' }
      );

      recaptcha.verify().then(() => getOtp(recaptcha));
    }
  }, [orgNumber]);

  return (
    <CardWithTitle
      title={
        orgNumber
          ? 'This helps us keep your account secure by verifying that it’s really you.'
          : 'Setup Two-Factor Authentication (2FA)'
      }
    >
      <Typography variant="body1" className={classes.enableText}>
        {orgNumber ? "Proceed with OTP": "Enter Mobile Number"}
      </Typography>
      <Typography variant="body2" className={classes.instructionText}>
        We will text an 6 digit secure code to this number whenever you sign in to your Mindlance
        account.
      </Typography>
      <form
        onSubmit={e => {
          e.preventDefault();
          const recaptcha = new firebase.auth.RecaptchaVerifier(
            'recaptcha-container',
            { size: 'invisible' }
          );

          recaptcha.verify().then(() => getOtp(recaptcha));
        }}
      >
        <PhoneInput
          gridLayout={true}
          label="Phone Number"
          value={number}
          onChange={val => {
            setNumber(val);
          }}
          disabled={orgNumber}
        />
        <div id="recaptcha-container"></div>

        <Box className={classes.buttonContainer}>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              handleAuthChange(!props.isGoogle ? '' : 'totp');
            }}
          >
            {!props.isGoogle
              ? 'Try Another Way'
              : props?.totp
              ? 'Use Authenticator'
              : 'Setup Authenticator App'}
          </Button>
          <Box display="flex" justifyContent="flex-end">
            {!props.totp && (
              <Button
                variant="outlined"
                color="primary"
                className={classes.backButton}
                onClick={() => {
                  onBack();
                }}
              >
                Back
              </Button>
            )}

            <Button variant="contained" type="submit" color="primary">
              Get Code
            </Button>
          </Box>
        </Box>
      </form>
    </CardWithTitle>
  );
};

export default EnterNumberForm;
