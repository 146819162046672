import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Link as MuiLink,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  showSnackbar,
  startPageLoader,
  stopPageLoader,
} from '../../redux/actions/appAction';
import { useDispatch } from 'react-redux';
import { sendEmailForOtp, verifyEmailForOtp } from '../../api';
import StandardTextField from '../designSystem/StandardTextField';
import EmailIcon from './icons/EmailIcon';
import SkuadBlackLogo from '../../images/skuad-logo-black.svg';
import { maskEmail } from './EmailOtp';
import ResendCode from './ResendCode';

const useStyles = makeStyles(theme => ({
  enableText: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  instructionTopText: {
    fontWeight: 600,
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  instructionBottomText: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    gap: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
}));

export const purposeMap = {
  phone_setup: 'enable your mobile authentication',
  phone_change: 'change your mobile number',
  totp_setup: 'enable your authenticator app authentication',
  totp_change: 'change your authenticator app authentication',
  '2fa_otp': 'verify your email authentication',
};


const EmailOtpBackDrop = ({ totp, onVerify, purpose, onBack, email }) => {
  const classes = useStyles();
  const _dispatch = useDispatch();
  const [token, setToken] = useState('');
  const [result, setResult] = useState({});
  const [lastSent, setLastSent] = useState(null);

  const sendEmailOtp = useCallback(async () => {
    _dispatch(startPageLoader());
    try {
      const response = await sendEmailForOtp(purpose);
      _dispatch(showSnackbar('Email sent successfully'));
      setResult(response.data);
      setLastSent((new Date()).toISOString())
    } catch (error) {
      _dispatch(showSnackbar(error?.response?.data?.message || error.message));
    } finally {
      _dispatch(stopPageLoader());
    }
  });

  const verifyOtp = async () => {
    try {
      _dispatch(startPageLoader());
      const res = await verifyEmailForOtp(token, result.token);
      if (res) {
        onVerify();
      }
    } catch (error) {
      console.error(error);
      _dispatch(showSnackbar(error?.response?.data?.message || error.message));
    } finally {
      _dispatch(stopPageLoader());
    }
  };

  const handleOtpChange = e => {
    if (e.target.value.length > 6) {
      return;
    }
    setToken(e.target.value);
  };

  useEffect(() => {
    sendEmailOtp();
  }, []);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        verifyOtp();
      }}
    >
      <Backdrop
        style={{ backgroundColor: '#FEFAFA', zIndex: 2000 }}
        open={true}
      >
        <Card>
          <CardContent>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box mb={3}>
                <img src={SkuadBlackLogo} alt="Mindlance" />
              </Box>
              <Typography variant="h4" style={{ marginBottom: 8 }}>
                Verify it's you
              </Typography>

              <Typography
                variant="body2"
                className={classes.instructionTopText}
              >
                Mindlance wants to make sure that it's you trying to{' '}
                {purposeMap[purpose]}
              </Typography>
              <Typography
                variant="body2"
                className={classes.instructionBottomText}
              >
                Enter the 6-digit secure code sent to ({maskEmail(email)}) to{' '}
                {purposeMap[purpose]}
              </Typography>
              <Box mb={3}>
                <EmailIcon />
              </Box>

              <StandardTextField
                gridLayout={false}
                placeholder="6 digit secure code"
                value={token}
                onChange={handleOtpChange}
              />
              <Box
                display="flex"
                sx={{ width: '100%' }}
                justifyContent="space-between"
                alignItems="baseline"
              >
                <Typography variant="caption"></Typography>
                <ResendCode onClick={() => sendEmailOtp()} lastSent={lastSent} />  
              </Box>

              <Box className={classes.buttonContainer}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.backButton}
                    onClick={() => {
                      onBack();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={token.length !== 6}
                  >
                    Confirm
                  </Button>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Backdrop>
    </form>
  );
};

EmailOtpBackDrop.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleAuthChange: PropTypes.func.isRequired,
  totp: PropTypes.bool.isRequired,
  redirectURL: PropTypes.string.isRequired,
  phone_verified: PropTypes.bool.isRequired,
};

export default EmailOtpBackDrop;
