import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Box, Card, CardContent, Divider, Link as MuiLink, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { showSnackbar, startPageLoader, stopPageLoader } from '../../redux/actions/appAction';
import EmailSignupVerify from './icons/EmailSignupVerify';

const useStyles = makeStyles(theme => ({
  enableText: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  instructionTopText: {
    fontWeight: 600,
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  instructionBottomText: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    gap: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  card: {
    borderRadius: theme.spacing(1),
    maxWidth: 650,
  },
  emailSentMessage: {
    marginBottom: theme.spacing(1)
  },
  bottom: {
    fontSize: 12,
    textAlign: 'center',
  },
  backdrop: {
    backgroundColor: '#FEFAFA',
    zIndex: 2000,
  },
}));

const VerifyEmailBackDrop = ({ email, onResend }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <Card className={classes.card}>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box mb={3} paddingLeft={10}>
              <EmailSignupVerify />
            </Box>
            <Typography variant="h5" gutterBottom className={classes.enableText}>
              Thank you for signing up!
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              align="center"
              className={classes.emailSentMessage}
            >
              A confirmation email has been sent to {email}.<br /> Please click the link in the email to verify your
              account.
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              If you don't see the email in your inbox, please check your spam or junk folder.
            </Typography>
            <Divider style={{ width: '100%', margin: 24 }} />
            <Typography mt={2} className={classes.bottom} alignItems="center">
              Still didn't receive the email? <MuiLink style={{cursor: 'pointer'}} onClick={onResend}>Resend Confirmation Email</MuiLink> or
              contact our support team at<br /> <MuiLink>csm@payplus.in</MuiLink>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Backdrop>
  );
};

VerifyEmailBackDrop.propTypes = {
  email: PropTypes.string.isRequired,
};

export default VerifyEmailBackDrop;
