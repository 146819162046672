import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Link as MuiLink,
  Box,
} from '@material-ui/core';
import StandardTextField from '../../designSystem/StandardTextField';
import PasswordInput from '../../common/PasswordInput';
import AuthenticationCTAs from '../../common/AuthenticationCTAs';
import { updateQueryParam } from '../../../utils/generic';

const bottomHelperText = navigateToSignupPage => (
  <Typography variant="body2" align="center">
    Need to create an account?{' '}
    <MuiLink
      href="#"
      onClick={navigateToSignupPage}
      id="form-signIn-signUpLink"
    >
      Sign up
    </MuiLink>
  </Typography>
);

const SigninForm = ({
  state,
  onChangeHandler,
  onSubmit,
  onSigninButtonProps,
  onGoogleSigninButtonProps,
  onCaptchaClickHandler,
}) => {
  const location = useLocation();
  const history = useHistory();

  const navigateToSignupPage = e => {
    e.preventDefault();
    const queryParams = [
      {
        parameter: 'screen',
        value: 'signup',
      },
    ];
    history.push({
      pathname: location.pathname,
      search: updateQueryParam(queryParams, location.search),
    });
  };

  const navigateToForgotPasswordPage = e => {
    e.preventDefault();
    const queryParams = [
      {
        parameter: 'screen',
        value: 'forgotPassword',
      },
    ];
    history.push({
      pathname: location.pathname,
      search: updateQueryParam(queryParams, location.search),
    });
  };

  return (
    <Box pt={3} id="form-signIn">
      <Card>
        <CardContent>
          <form noValidate onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4" fontWeight={600}>
                  Welcome to Mindlance
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <StandardTextField
                  id="form-signIn-emailInput"
                  label="Your Email ID"
                  name="email"
                  type="email"
                  required
                  value={state.formData.email}
                  onChange={onChangeHandler}
                  error={!!state.errorData.email}
                  helperText={state.errorData.email}
                  inputProps={{ tabIndex: state.formData.email ? '1' : '2' }}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordInput
                  id="form-signIn-passwordInput"
                  name="password"
                  label="Password"
                  required
                  value={state.formData.password}
                  onChange={onChangeHandler}
                  error={!!state.errorData.password}
                  helperText={state.errorData.password}
                  inputProps={{ tabIndex: '1' }}
                />
              </Grid>
              <AuthenticationCTAs
                enableDivider
                onForgotPasswordClick={navigateToForgotPasswordPage}
                onSigninButtonProps={onSigninButtonProps}
                onGoogleSigninButtonProps={onGoogleSigninButtonProps}
                onCaptchaClickHandler={onCaptchaClickHandler}
                // bottomHelperText={bottomHelperText(navigateToSignupPage)}
              />
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SigninForm;
