import React from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Link,
} from '@material-ui/core';
import StandardTextField from '../../designSystem/StandardTextField';
import PasswordInput from '../../common/PasswordInput';
import CheckboxGroupInput from '../../designSystem/CheckboxGroupInput';
import AuthenticationCTAs from '../../common/AuthenticationCTAs';
import { USER_TYPES } from '../../../utils/constants';

export const bottomHelperText = (
  <Typography variant="body2" align="center">
    By signing up, you agree to the{' '}
    <Link
      href="https://www.payplus.in/terms-conditions"
      id="form-signUp-termsOfServiceLink"
      target="__blank"
    >
      Terms of Service
    </Link>{' '}
    and{' '}
    <Link
      href="https://www.payplus.in/privacy"
      id="form-signUp-privacyPolicyLink"
      target="__blank"
    >
      Privacy Policy
    </Link>
    .
  </Typography>
);

export const checkbox = (
  // 'By checking the box below, you agree to the Mindlance Privacy Policy and Terms & Conditions.',

  <Typography variant="body2" align="center">
    By checking the box, you agree to the{' '}
    <Link
      href="https://www.payplus.in/terms-conditions"
      id="form-signUp-termsOfServiceLink"
      target="__blank"
    >
      Terms of Service
    </Link>{' '}
    and{' '}
    <Link
      href="https://www.payplus.in/privacy"
      id="form-signUp-privacyPolicyLink"
      target="__blank"
    >
      Privacy Policy
    </Link>
    .
  </Typography>
);

const SignupForm = ({
  state,
  onChangeHandler,
  onSubmit,
  onSignupButtonProps,
  onGoogleSignupButtonProps,
  onCaptchaClickHandler,
}) => {
  const location = useLocation();
  const { userType } = parse(location.search);

  const firstName =
    state.invited && !!state.formData.fullName
      ? ' ' + state.formData.fullName.split(' ')[0]
      : '';

  return (
    <Card>
      <CardContent>
        <form noValidate onSubmit={onSubmit} id="form-signUpForm">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">
                Hey{firstName}, start building your global team now.
              </Typography>
              <Divider />
            </Grid>
            {/* <Grid item xs={12}>
              <StandardTextField
                id="form-signUp-fullNameInput"
                label="Your Full Name"
                required
                name="fullName"
                value={state.formData.fullName}
                onChange={onChangeHandler}
                error={!!state.errorData.fullName}
                helperText={state.errorData.fullName}
                inputProps={{ tabIndex: '1' }}
              />
            </Grid> */}
            {userType === USER_TYPES.Client && (
              <Grid item xs={12}>
                <StandardTextField
                  id="form-signUp-companyNameInput"
                  label="Company Name"
                  required
                  name="companyName"
                  value={state.formData.companyName}
                  onChange={event => {
                    onChangeHandler(event);
                  }}
                  disabled={state.invited && !!state.formData.companyName}
                  error={!!state.errorData.companyName}
                  helperText={state.errorData.companyName}
                  inputProps={{ tabIndex: '2' }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <StandardTextField
                id="form-signUp-emailInput"
                label="Your Email ID"
                type="email"
                required
                name="email"
                value={state.formData.email}
                onChange={onChangeHandler}
                disabled={state.invited && !!state.formData.email}
                error={!!state.errorData.email}
                helperText={state.errorData.email}
                inputProps={{ tabIndex: '3' }}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                id="form-signUp-passwordInput"
                label="Create Password"
                name="password"
                required
                value={state.formData.password}
                onChange={onChangeHandler}
                error={!!state.errorData.password}
                helperText={state.errorData.password}
                inputProps={{
                  'data-associatedFieldForValidation': 'confirmPassword',
                  tabIndex: '4',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                id="form-signUp-confirmPasswordInput"
                label="Confirm Password"
                name="confirmPassword"
                required
                value={state.formData.confirmPassword}
                onChange={onChangeHandler}
                error={!!state.errorData.confirmPassword}
                helperText={state.errorData.confirmPassword}
                inputProps={{ tabIndex: '5' }}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxGroupInput
                id="form-signUp-newsLetterSubscriptionInput"
                name="newsLetterSubscription"
                options={[
                  {
                    label: checkbox,
                    value: 'true',
                  },
                ]}
                value={state.formData.newsLetterSubscription}
                onChange={onChangeHandler}
                error={!!state.errorData.newsLetterSubscription}
                helperText={state.errorData.newsLetterSubscription}
                inputProps={{ tabIndex: '6' }}
              />
            </Grid>
            <AuthenticationCTAs
              enableDivider={true}
              onSignupButtonProps={onSignupButtonProps}
              onGoogleSignupButtonProps={onGoogleSignupButtonProps}
              onCaptchaClickHandler={onCaptchaClickHandler}
              bottomHelperText={bottomHelperText}
              signBottomHelper={true}
            />
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default SignupForm;
